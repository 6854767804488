<template>
  <v-layout row align-content-space-around xs12>
    <v-dialog
      v-model="cropImageModalData.dialog"
      :max-width="dialogMaxWidth"
      :max-height="dialogMaxHeight"
      hide-overlay
      width="800"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline"
                >{{ $t("MEDIA_LIBRARY.edit_image") }}
              </span>
              <v-btn
                @click="handleCloseCropImageModalForm"
                icon
                class="close-button"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle>{{ filename }}</v-card-subtitle>
        <v-card-text>
          <vue-cropper
            v-show="!loader"
            ref="cropper"
            :guides="false"
            :view-mode="0"
            drag-mode="move"
            :auto-crop-area="0.5"
            :min-container-width="250"
            :min-container-height="180"
            :background="true"
            :rotatable="true"
            :src="imgSrc"
            alt="Source Image"
            :modal="true"
            :img-style="{ width: '400px', height: '400px' }"
            :center="false"
            :highlight="true"
          />

          <!--<v-card
                        v-else
                        color="primary"
                        dark
                    >
                        <v-card-text>
                              <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                              ></v-progress-linear>
                        </v-card-text>
                    </v-card>-->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseCropImageModalForm">
            {{ $t("FORMS.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="cropAndSaveImage()">{{
            $t("MEDIA_LIBRARY.crop_and_save")
          }}</v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-layout>
</template>
<script>
import VueCropper from "vue-cropperjs";
import ApiService from "@/core/services/api.service";
import axios from "axios";
export const BASE64_IMAGE_ENDPOINT = "attachment/base64-image";
import { FILE_ENDPOINT } from "./Browser";
export default {
  name: "CropImageModalForm",
  components: { VueCropper },
  props: [
    "cropImageModalData",
    "actualDirectory",
    {
      value: {},
      pk: { default: "image_key" },
      dialogMaxWidth: { default: "200px" },
      dialogMaxHeight: { default: "0.8vh" },
      maxWidth: { default: 1920 },
      maxHeight: { default: 1200 },
      // the URL of the blob image
      objectUrl: { default: "" },
    },
  ],

  data() {
    return {
      loader: false,
      imgSrc: "",
      cropImg: null,
      dialog: false,
      file: null,
      filename: null,
      cropBlob: null,
    };
  },
  watch: {
    cropImageModalData: {
      deep: true,
      handler(value) {
        if (value.path) {
          this.loader = true;
          ApiService.get(BASE64_IMAGE_ENDPOINT, value.path)
            .then((response) => {
              if (response.status == 200 && response.statusText == "OK") {
                if (typeof response.data.base64_data != "undefined") {
                  this.setImage(response.data);
                }
              } else {
                console.log(response);
              }
            })
            .catch((error) => {
              this.loader = false;
              console.log(error);
            });
        }
      },
    },
  },
  /*computed: {
            croppedFile() {
                alert('croppedFile');
                return new File([this.cropImg], this.file.name, { type: this.file.type });
            },
            cropSrc() {
                alert('cropSrc');
                return this.cropImg || this.value;
            }
        },*/
  methods: {
    handleCloseCropImageModalForm() {
      this.filename = null;
      this.$emit("closeCropImageModalForm");
    },
    setImage(data) {
      this.filename = data.file_name;

      const byteCharacters = atob(data.base64_data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: data.mime_type });

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          this.$emit("update:dataUrl", this.imgSrc);
          this.loader = false;
        };
        reader.readAsDataURL(blob);
      } else {
        this.loader = false;
        alert("Sorry, FileReader API not supported");
      }
    },
    cropAndSaveImage() {
      // get image data for post processing, e.g. upload or setting image src
      // this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
        })
        .toBlob(
          (blob) => {
            /*this.cropImg = URL.createObjectURL(blob);
                            this.croppedBlob = blob;
                            this.$emit("update:objectUrl", this.cropImg);*/
            const formData = new FormData();
            formData.append("files[]", blob, Date.now() + " " + this.filename);
            formData.append("path", this.actualDirectory);
            //alert(this.actualDirectory);
            axios
              .post(FILE_ENDPOINT, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(() => {
                this.$emit("cropAndSaveImageModalForm");
              })
              .catch((error) => {
                console.log(error);
              });
          },
          "image/jpeg",
          0.98
        );
    },
  },
};
</script>
