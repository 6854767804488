import i18nService from "@/core/services/i18n.service.js";

export const formModelMixins = {
  data() {
    return {
      loader: false,
      errorLang: null,
    };
  },
  methods: {
    handleCloseModalForm() {
      this.resetErrorMessages();
      this.$emit("closeModalForm");
    },

    setTranslatedAttributes() {
      if (this.formModel && this.formModel.translations) {
        if (!this.formModel.id) {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                // this.formModel.translations.push(language.lang);
                this.formModel.translations[language.lang] = {};
              }
              this.formModel.translations[language.lang][attribute] = null;
            });
            if (this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang]["attachments"] = [];
            }
          });
        } else {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                this.formModel.translations[language.lang] = {};
                this.formModel.translations[language.lang][attribute] = null;
              }
            });
          });
        }
      }

      this.initTranslatedCustomFields();
    },

    // deleteUnusedTranslations() {
    //   let translations = {};
    //   if (this.translatedAttributes.length > 0) {
    //     i18nService.languages.forEach((item) => {
    //       let langCode = item.lang;
    //       let isTranslated = false;
    //       this.translatedAttributes.forEach((attribute) => {
    //         if (
    //           this.formModel.translations[langCode] &&
    //           this.formModel.translations[langCode][attribute] &&
    //           this.formModel.translations[langCode][attribute] != ""
    //         ) {
    //           isTranslated = true;
    //         }
    //       });
    //       if (isTranslated) {
    //         translations[langCode] = Object.assign(
    //           {},
    //           this.formModel.translations[langCode]
    //         );
    //       }
    //     });

    //     if (Object.keys(translations).length == 0) {
    //       translations[this.selectedLocale.lang] =
    //         this.formModel.translations[this.selectedLocale.lang];
    //     }
    //   }

    //   return translations;
    // },

    deleteUnusedTranslations(model) {
      let translations = {};
      if (this.translatedAttributes.length > 0) {
        i18nService.languages.forEach((item) => {
          let langCode = item.lang;

          this.translatedAttributes.forEach((attribute) => {
            if (
              model.translations[langCode] &&
              model.translations[langCode][attribute] &&
              attribute != "custom_fields"
            ) {
              // console.log(
              //   "!!!! model.translations[langCode]",
              //   model.translations[langCode],
              //   langCode,
              //   attribute,
              //   model.translations[langCode][attribute]
              // );
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
        });
      }

      model.translations = translations;

      return model;
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, this.initialFormData());
      this.languages.forEach((language) => {
        this.translatedAttributes.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
      this.errorLang = null;
    },

    // setError(prop, value) {
    //   this.messages[prop] = value;
    // },

    setError(prop, value) {
      if (prop.search("translations.") != -1) {
        let langCode = prop.replace("translations.", "").substring(0, 2);

        if (!this.errorLang) {
          this.errorLang = langCode;
          this.selectedLocale = i18nService.languages.find((item) => {
            return item.lang == langCode;
          });
        } else {
          if (langCode == this.selectedLocale.lang) {
            this.errorLang = langCode;
            this.selectedLocale = i18nService.languages.find((item) => {
              return item.lang == langCode;
            });
          }
        }
      }
      this.messages[prop] = value;
    },

    handleCloseDelete() {
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },
  },
};
