<template>
  <div class="text-center">
    <v-dialog v-model="directoryModalData.dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" class="ml-4">
          {{ $t("MEDIA_LIBRARY.create_new_directory") }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span v-if="directoryModalData.old_name" class="headline"
                >{{ $t("MEDIA_LIBRARY.rename_directory") }}
              </span>
              <span v-else class="headline"
                >{{ $t("MEDIA_LIBRARY.create_new_directory") }}
              </span>
              <v-btn
                @click="handleCloseDirectoryModalForm"
                icon
                class="close-button"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-if="directoryModalData.old_name"
                  v-model="formModel.new_name"
                  :label="$t('FORMS.name')"
                  :rules="newNameRules"
                  :error-messages="messages['new_name']"
                  @keyup="messages['new_name'] = ''"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="formModel.name"
                  :label="$t('FORMS.name')"
                  :rules="nameRules"
                  :error-messages="messages['name']"
                  @keyup="messages['name'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseDirectoryModalForm">
            {{ $t("FORMS.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="handleSaveDirectoryModalForm">
            {{ $t("FORMS.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { DIRECTORY_ENDPOINT } from "./Browser";
export const initialFormData = () => ({
  name: "",
  //new_name: '',
});

export default {
  name: "DirectoryModalForm",
  props: ["directoryModalData", "actualDirectory"],
  data: () => ({
    loader: false,
    formModel: Object.assign({}, initialFormData()),
    formValid: false,
    messages: {},
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length < 250 || "This field must be max 250 characters",
    ],
    newNameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length < 250 || "This field must be max 250 characters",
    ],
  }),
  watch: {
    directoryModalData: {
      deep: true,
      handler(value) {
        if (value.old_name) {
          this.formModel.new_name = value.old_name;
        }
      },
    },
  },
  methods: {
    handleCloseDirectoryModalForm() {
      this.$emit("closeDirectoryModalForm");
    },
    handleSaveDirectoryModalForm() {
      let model = Object.assign({}, this.formModel);
      model.path = this.actualDirectory;
      this.$refs.form.validate();
      if (this.formValid) {
        if (this.directoryModalData.old_name) {
          model.old_name = this.directoryModalData.old_name;
          this.loader = true;
          ApiService.put(DIRECTORY_ENDPOINT, model)
            .then((response) => {
              if (response.status == 200 && response.statusText == "OK") {
                this.old_name = false;
                this.$emit("saveDirectoryModalForm");
              } else {
                console.log(response);
              }
              this.loader = false;
            })
            .catch((error) => {
              console.log(error);
              this.loader = false;
            });
        } else {
          this.loader = true;
          ApiService.post(DIRECTORY_ENDPOINT, model)
            .then((response) => {
              if (response.status == 201 && response.statusText == "Created") {
                this.$emit("saveDirectoryModalForm");
              } else {
                console.log(response);
              }
              this.loader = false;
            })
            .catch((error) => {
              console.log(error);
              this.loader = false;
            });
        }
      }
    },
    setError(prop, value) {
      this.messages[prop] = value;
    },
  },
};
</script>
