import ApiService from "@/core/services/api.service";

export const listModelsMixins = {
  data() {
    return {
      loadingTable: false,
    };
  },
  methods: {
    editItem(id) {
      this.$nextTick(() => {
        this.modalData.editedId = id;
        this.modalData.dialog = true;
      });
    },

    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(this.routePath + item.id);
      }
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchModel();

      if (
        this.$route.path.replaceAll("/", "") !=
        this.routePath.replaceAll("/", "")
      )
        this.$router.push(this.routePath);
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      ApiService.delete(this.endPoint + this.editedItem.id)
        .then(() => {
          this.fetchModel();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },
  },
};
